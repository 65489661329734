/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, css } from '@emotion/core'
import {
  X,
  Chevron
} from 'reline'

import treeicon from '../../../assets/images/icons/tree.svg'
import questioncircle from '../../../assets/images/icons/questioncircle.svg'
import icon2 from '../../../assets/images/icons/2.svg'
import icon25 from '../../../assets/images/icons/2_5.svg'
import icon35 from '../../../assets/images/icons/3_5.svg'
import icon3 from '../../../assets/images/icons/3.svg'
import icon4 from '../../../assets/images/icons/4.svg'
import iconfull_sheet from '../../../assets/images/icons/full_sheet.png'
import iconhalf_sheet from '../../../assets/images/icons/half_sheet.png'
import roomicon from '../../../assets/images/house.png'
import chevronsvg from '../../../assets/images/icons/chevron.svg'
import whitechevronsvg from '../../../assets/images/icons/white-chevron.svg'

const SearchIcon = (
  <svg
    css={css`
            ${tw`fill-current text-border-blue h-5 w-5`}
          `}
    viewBox="0 0 20 20"
  >
    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
  </svg>
);

const RollsIcon = (
  <svg width="93px" height="48px" viewBox="0 0 93 48" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Designs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="09-Velour-SKU-Page-" transform="translate(-729.000000, -496.000000)">
        <g id="Group-2" transform="translate(732.000000, 499.000000)">
          <g id="Group" transform="translate(0.000000, 6.000000)" fill="#FFFFFF">
            <rect id="Rectangle-12" stroke="#4D8DCD" strokeWidth="3" x="-1.5" y="3.5" width="26" height="19" />
            <ellipse id="Oval-Copy" stroke="#4D8DCD" strokeWidth="3" cx="11.5" cy="23.5" rx="13" ry="4" />
            <rect id="Rectangle-12" x="0" y="5" width="23" height="19" />
            <ellipse id="Oval" stroke="#4D8DCD" strokeWidth="3" cx="11.5" cy="2.5" rx="13" ry="4" />
            <ellipse id="Oval" stroke="#4D8DCD" cx="11.5" cy="2.5" rx="3" ry="1" />
          </g>
          <g id="Group-Copy" transform="translate(53.000000, 0.000000)" fill="#FFFFFF">
            <rect id="Rectangle-12" stroke="#4D8DCD" strokeWidth="3" x="-1.5" y="3.5" width="23" height="19" />
            <ellipse id="Oval-Copy" stroke="#4D8DCD" strokeWidth="3" cx="10" cy="23.5" rx="11.5" ry="4" />
            <rect id="Rectangle-12" x="0" y="5" width="20" height="18.5" />
            <ellipse id="Oval" stroke="#4D8DCD" strokeWidth="3" cx="10" cy="2.5" rx="11.5" ry="4" />
            <ellipse id="Oval" stroke="#4D8DCD" cx="10" cy="2.5" rx="2.5" ry="1" />
          </g>
          <g id="outline-roll-icon" transform="translate(62.000000, 11.000000)">
            <path d="M3,8 L3,24 L23,24 L23,8 L3,8 Z M25.9718959,5 L26,5 L26,27 L25.9718959,27 C25.5411646,30.7402891 20.203315,32 13,32 C5.79668505,32 0.458835424,30.7402891 0.0281041432,27 L0,27 L0,5 L0.0281041432,5 C0.458835424,1.25971092 5.79668505,0 13,0 C20.203315,0 25.5411646,1.25971092 25.9718959,5 Z" id="Combined-Shape" stroke="#FFFFFF" strokeWidth="4" fill="#4D8DCD" fillRule="nonzero" strokeLinejoin="round" />
            <rect id="Rectangle-12" stroke="#4D8DCD" strokeWidth="3" fill="#FFFFFF" x="1.5" y="6.5" width="23" height="19" />
            <ellipse id="Oval-Copy" stroke="#4D8DCD" strokeWidth="3" fill="#FFFFFF" cx="13" cy="26.5" rx="11.5" ry="4" />
            <rect id="Rectangle-12" fill="#FFFFFF" x="3" y="8" width="20" height="18.5" />
            <ellipse id="Oval" stroke="#4D8DCD" strokeWidth="3" fill="#FFFFFF" cx="13" cy="5.5" rx="11.5" ry="4" />
            <ellipse id="Oval" stroke="#4D8DCD" fill="#FFFFFF" cx="13" cy="5.5" rx="2.5" ry="1" />
          </g>
          <rect id="Rectangle-13" fill="#4D8DCD" x="34" y="16" width="7" height="2" />
          <rect id="Rectangle-13-Copy" fill="#4D8DCD" x="34" y="20" width="7" height="2" />
        </g>
      </g>
    </g>
  </svg>
);

const LanguageIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 512'>
    <path fill='currentColor' d='M152.1 236.2c-3.5-12.1-7.8-33.2-7.8-33.2h-.5s-4.3 21.1-7.8 33.2l-11.1 37.5H163zM616 96H336v320h280c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24zm-24 120c0 6.6-5.4 12-12 12h-11.4c-6.9 23.6-21.7 47.4-42.7 69.9 8.4 6.4 17.1 12.5 26.1 18 5.5 3.4 7.3 10.5 4.1 16.2l-7.9 13.9c-3.4 5.9-10.9 7.8-16.7 4.3-12.6-7.8-24.5-16.1-35.4-24.9-10.9 8.7-22.7 17.1-35.4 24.9-5.8 3.5-13.3 1.6-16.7-4.3l-7.9-13.9c-3.2-5.6-1.4-12.8 4.2-16.2 9.3-5.7 18-11.7 26.1-18-7.9-8.4-14.9-17-21-25.7-4-5.7-2.2-13.6 3.7-17.1l6.5-3.9 7.3-4.3c5.4-3.2 12.4-1.7 16 3.4 5 7 10.8 14 17.4 20.9 13.5-14.2 23.8-28.9 30-43.2H412c-6.6 0-12-5.4-12-12v-16c0-6.6 5.4-12 12-12h64v-16c0-6.6 5.4-12 12-12h16c6.6 0 12 5.4 12 12v16h64c6.6 0 12 5.4 12 12zM0 120v272c0 13.3 10.7 24 24 24h280V96H24c-13.3 0-24 10.7-24 24zm58.9 216.1L116.4 167c1.7-4.9 6.2-8.1 11.4-8.1h32.5c5.1 0 9.7 3.3 11.4 8.1l57.5 169.1c2.6 7.8-3.1 15.9-11.4 15.9h-22.9a12 12 0 0 1-11.5-8.6l-9.4-31.9h-60.2l-9.1 31.8c-1.5 5.1-6.2 8.7-11.5 8.7H70.3c-8.2 0-14-8.1-11.4-15.9z' />
  </svg>
)

const Icon = (props) => {
  const { icon, width, className } = props;

  switch (icon) {
    case 'search':
      return SearchIcon
    case 'rolls':
      return RollsIcon
    case 'language':
      return LanguageIcon
    case 'tree':
      return (
        <img
          src={treeicon}
          alt="icon"
          css={css`
            width: ${parseFloat(width)}px
          `}
        />
      )
    case 'questioncircle':
      return (
        <img
          src={questioncircle}
          alt="icon"
          css={css`
            width: ${parseFloat(width)}px
          `}
        />
      )
    case '1_2':
      return (
        <img
          src={icon2}
          alt="icon"
          css={css`
            width: ${parseFloat(width)}px
          `}
        />
      )
    case '1_26':
      return (
        <img
          src={icon25}
          alt="icon"
          css={css`
            width: ${parseFloat(width)}px
          `}
        />
      )
    case '1_3':
      return (
        <img
          src={icon3}
          alt="icon"
          css={css`
            width: ${parseFloat(width)}px
          `}
        />
      )
    case '1_35':
      return (
        <img
          src={icon35}
          alt="icon"
          css={css`
            width: ${parseFloat(width)}px
          `}
        />
      )
    case '1_4':
      return (
        <img
          src={icon4}
          alt="icon"
          css={css`
            width: ${parseFloat(width)}px
          `}
        />
      )
    case 'full_sheet':
      return (
        <img
          src={iconfull_sheet}
          alt="icon"
          css={css`
            width: ${parseFloat(width)}px
          `}
        />
      )
    case 'half_sheet':
      return (
        <img
          src={iconhalf_sheet}
          alt="icon"
          css={css`
            width: ${parseFloat(width)}px
          `}
        />
      )
    case 'room':
      return (
        <img
          src={roomicon}
          alt="icon"
          css={css`
            width: ${parseFloat(width)}px;
          `}
          className={className}
        />
      )
    case 'close':
      return (
        <X {...props} />
      )
    case 'chevron':
      return (
        <Chevron {...props} />
      )
    case 'bigchevron':
      return (
        <img
          src={chevronsvg}
          alt="icon"
          css={css`
            width: ${parseFloat(width)}px;
          `}
          className={className}
        />
      )
    case 'whitechevron':
      return (
        <img
          src={whitechevronsvg}
          alt="icon"
          css={css`
            width: ${parseFloat(width)}px;
          `}
          className={className}
        />
      )
    default:
      return null
  }
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired
}

export default Icon
